

























import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'ChemUOtt1721BM6_Task27',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
      options: [
        {img: '/img/assignments/uOtt1721B_M6Task27_optionA.png', value: 'a'},
        {img: '/img/assignments/uOtt1721B_M6Task27_optionB.png', value: 'wrongConnect'},
        {img: '/img/assignments/uOtt1721B_M6Task27_optionC.png', value: 'grignardBlasphemy'},
        {img: '/img/assignments/uOtt1721B_M6Task27_optionD.png', value: 'noReaction'},
      ],
    };
  },
};
